import Rout from './Route';
import 'tailwindcss/tailwind.css'
 
function App() {
  return (
    <div className="w-full text-center">
      <Rout/>
    </div>
  );
}

export default App;
