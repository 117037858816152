import galvanizeFrame from '../images/galvanizeFrame.jpg'
import galary1 from '../images/IMG-20230908-WA0000.jpg'
import galary2 from '../images/IMG-20230908-WA0001 (1).jpg'
import galary3 from '../images/IMG-20230908-WA0001.jpg'
import galary4 from '../images/IMG-20230908-WA0002.jpg'
import galary5 from '../images/IMG-20230908-WA0003.jpg'
import galary6 from '../images/IMG-20230908-WA0004.jpg'
import galary7 from '../images/IMG-20230908-WA0006.jpg'
import galary8 from '../images/IMG-20230908-WA0007.jpg'
import galary9 from '../images/IMG-20230908-WA0009.jpg'
import galary10 from '../images/IMG-20230908-WA0010.jpg'
import galary11 from '../images/IMG-20230908-WA0011.jpg'
import galary12 from '../images/IMG-20230908-WA0012.jpg'
import galary13 from '../images/IMG-20230908-WA0013.jpg'
import galary14 from '../images/IMG-20230908-WA0014.jpg'
import galary15 from '../images/IMG-20230910-WA0001.jpg'

export const Data =[
   {
     img:`${galary1}`
    },
    {
      img:`${galary2}`
    },
    {
      img:`${galary3}`
    },
    {
      img:`${galary4}`
    },
    {
      img:`${galary5}`
    },
    {
      img:`${galary6}`
    },
    {
      
      img:`${galary7}`
    },
    {
      
      img:`${galary8}`
    },
    {  
      img:`${galary9}`
    },
    {  
      img:`${galary10}`
    },
    {
      img:`${galary11}`
    },
    {
      img:`${galary12}`
    },
    {
      img:`${galary13}`
    },
    {
      img:`${galary14}`
    },
    {
      img:`${galary15}`
    }
]